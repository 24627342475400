/*
Theme Name: Ask Christee theme
Author: Sherri Ferritto Williams
Author URI: https://www.metisdreams.com
Developer: SFW
Version: 2.0
*/


/* ----------------------------------------------
THEME STYLES
------------------------------------------------- */
body, html {
	margin: 0px;
	padding: 0px;
	font-family: 'Open Sans', sans-serif;
	/*color:#363946;*/
	color:#47505C;
	background-color:#FFF;
	font-size: 16px;
	font-size: 100%;
	-webkit-text-size-adjust: none;
	position: relative;
	height: 100%;
	width: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: none;
}
/*========= basic global font sizes =============*/

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
 margin-bottom: .5rem;
	font-weight: 700;
	line-height: 1.2;
	color:#0E316C;
 margin-bottom: .65rem;
}
h1 {
	font-size:28px;
	margin-bottom: 1rem;
	color:#0E316C;
	letter-spacing:-.025em;
}
h2, .h2 {
	font-size:26px;
	letter-spacing:-.025em;
	margin-top: 15px;
}
.content h2 {
}
h3, .h3 {
	font-size:18px;
}
p {
	font-size: 18px;
	line-height: 155%;
	margin-bottom: 10px;
}
.content ul.nobullets {
	margin-left:0;
}
.content ul li {
	font-size: 18px;
	line-height: 155%;
	letter-spacing:-.015em;
	margin-bottom: 10px;
	list-style:none;
}
.content ul {
	margin-left:15px;
}
/*
.content ul li:before {
	content: '\f111';
	font-family: 'FontAwesome';
	font-size: 6px;
	float: left;
	margin-left: -15px;
	color: #0E316C;
}
.content ul ul li:before {
	content: '\f10c';
	font-family: 'FontAwesome';
	font-size: 6px;
	float: left;
	margin-left: -15px;
	color: #0E316C;
}
.content ul.nobullets li:before {
	display:none;
} */
/* ordered lists */

 .content ol li {
	font-size: 18px;
	line-height: 155%;
	letter-spacing:-.015em;
	padding-bottom: 10px;
}
p.lead {
	font-size: 24px;
}
b, strong {
	font-weight: 700;
}
 @media only screen and (min-width: 768px) {
h1 {
font-size:24px;
}
h2, .h2 {
font-size:22px;
}
h3, .h3 {
font-size:18px;
}
p {
font-size: 16px;
}
.content ul li, .content ol li {
font-size: 16px;
}
p.lead {
font-size: 16px;
}
}
 @media only screen and (min-width: 992px) {
h1 {
font-size:26px;
}
h2, .h2 {
font-size:27px !important;
}
h3, .h3 {
font-size:24px;
}
}
@media only screen and (min-width: 1200px) {
h1 {
font-size:32px;
margin-bottom: 1.5rem;
}
 p {
font-size: 18px;
}
.content ul li, .content ol li {
font-size: 18px;
}
p.lead {
font-size: 24px;
}
}
/* --------- other helpers and overrides ---------*/

ul, menu, dir {
	list-style:none;
	padding:0;
	margin:0;
}
a, a:visited {
	color:#0E316C;
	text-decoration: none !important;
	transition: all 0.12s ease-in-out 0s;
}
a:hover, a:focus {
	color: #58B2BA;
	text-decoration: none;
}
a:focus {
	outline: thin dotted;
}
a.gray, a.gray:visited {
	color:#666;
}
a.gray:hover, a.gray:focus {
	color: #12408c;
}
a.read-more {
 padding-left:.5rem;
}
hr.gray {
	border-top: 2px solid #dce0e0;
}



/* telephone links */

a[href^="tel:"], a[href^="tel:"]:visited {
color: inherit;
font-weight:inherit;
text-decoration:none;
border:none;
}
 a[href^="tel:"]:hover {
color: inherit;
font-weight:inherit;
border:none;
}
img {
	max-width: 100%;
	height: auto
}
img.fullwidth-photo {
	width: 100%;
	height: auto
}
figure {
	padding: 0px;
	margin: 0px
}
.overflow {
	overflow:hidden;
}
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	white-space: nowrap;
	height: 1px;
	width: 1px;
	overflow: hidden
}
.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000
}
a#skiptocontent {
	padding: 6px;
	position: absolute;
	top: -60px;
	left: 0px;
	color:#FFF;
	background: #00A8E2;
	border-right: 1px solid white;
	border-bottom: 1px solid white;
	border-bottom-right-radius: 8px;
	background: transparent;
	-webkit-transition: top 1s ease-out, background 1s linear;
	transition: top 1s ease-out, background 1s linear;
	z-index: 100
}
a#skiptocontent:focus {
	position: absolute;
	left: 0px;
	top: 0;
	color:#FFF;
	background: #FF8300;
	outline: 0;
-webkit-transition: top .1s ease-in, background .5s linear;
transition: top .1s ease-in, background .5s linear;
	margin-bottom:200px;
}
.video-responsive {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
	margin-bottom:20px;
}
.video-responsive iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute
}
.video-border, .photo-border {
	border: 3px solid #FFF;
	margin-bottom: 30px
}
@media only screen and (min-width: 768px) {
.video-wrapper {
margin-bottom: 0px
}
}
@media only screen and (min-width: 1200px) {
.iframe-responsive.map {
margin-top:30px;
}
}
.iframe-container {
	overflow: hidden;
	padding-top: 56.25%;
	position: relative;
}
.iframe-container iframe {
	border: 0;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
.error-message {
	color:#cc0000;
	font-weight:bold;
}
/* =========== CONTAINERS ================= */
.container {
	width:95%;
	max-width:95%;
}

main.interior {
	margin-top: 100px;
}

 @media only screen and (max-width: 767px) {
.container.mobile-wide {
 width:97.5%;
 max-width:97.5%;
}
}
 @media only screen and (min-width: 768px) {
.container {
 width:90%;
 max-width:90%;
}
 .col-md-onefifth {
 -ms-flex: 0 0 20%;
 flex: 0 0 20%;
 max-width: 20%;
}
}
 @media only screen and (min-width: 1200px) {
 .col-lg-onefifth {
 -ms-flex: 0 0 20%;
 flex: 0 0 20%;
 max-width: 20%;
}
 .container-narrow {
 width:75%;
 max-width:75%;
}
}
 @media only screen and (min-width: 1600px) {
.container {
 width:86%;
 max-width:86%;
}
}
 @media (min-width: 1600px) {
.col-xxl-4 {
 -ms-flex: 0 0 33.333333%;
 flex: 0 0 33.333333%;
 max-width: 33.333333%;
}
}

/* custom gutters */

.lg-gutters > [class*='col-'] {
}
 @media(min-width:992px) {
 .lg-gutters {
 padding-right:-28px;
 padding-left:-28px;
}
 .lg-gutters > [class*='col-'] {
 padding-right:20px;
 padding-left:20px;
}
}
.card {
	background-color: transparent;
	background-clip: border-box;
	border: none;
	border-radius: 0;
}
.img-thumbnail {
	padding:3px;
	background-color: rgba(255, 255, 255, 0.7);
	border: 1px solid #dee2e6;
	border-radius: 0;
	max-width: 100%;
	height: auto;
}
.img-thumbnail:hover {
	background-color: #FFF;
	border: 1px solid #FFF;
}
/* =========== buttons and colors ================= */

.btn {
	font-weight: 700;
	letter-spacing:.03em;
	text-transform:uppercase;
	font-style: normal;
	font-size: 18px;
	line-height: 1.42857143;
	text-align: center;
	white-space:  normal;
	vertical-align: middle;
	display: inline-block;
	text-decoration: none;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
	border: 3px solid transparent;
	border-radius: 18px;
	margin-bottom: 0;
	padding: 8px;
	padding-right: 24px;
	padding-left: 24px;
}
.sherri .btn {
	font-weight: 700;
	letter-spacing:.03em;
	text-transform:uppercase;
	font-style: normal;
	font-size: 18px;
	line-height: 1.42857143;
	text-align: center;
	white-space:  normal;
	vertical-align: middle;
	display: inline-block;
	text-decoration: none;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
	border: 3px solid transparent;
	border-radius: 18px;
	margin-bottom: 0;
	padding: 8px;
	padding-right: 24px;
	padding-left: 24px;
}
 @media only screen and (min-width: 768px) {
.btn {
 font-size: 18px;
 border-radius: 18px;
}
}
 @media only screen and (min-width: 1200px) {
.btn {
 font-size: 24px;
 border-radius: 24px;
}
}

.btn-small {
	font-size: 16px;
	border-radius: 16px;
	padding: 6px;
	padding-right: 12px;
	padding-left: 12px;
}
.btn-orange, .btn-orange:visited {
	background-color: #E27A37;
	color: #FFF;
}
.btn-orange:hover, .btn-orange:focus {
	background-color: #df6b21;
	color: #FFF;
}
.btn-red, .btn-red:visited {
	background-color: #e6675f;
	color: #FFF;
}
.btn-red:hover, .btn-red:focus {
	background-color: #d72c22;
	color: #FFF;
}
.btn-grayblue, .btn-grayblue:visited {
	color: #FFF !important;
	background-color: #6f84a5 !important;
}
.btn-blue, .btn-blue:visited {
	color: #FFF;
	background-color: #0E316C;
}
.btn-blue:hover, .btn-blue:focus {
	color: #FFF;
	background-color:#12408c;
}
.btn-ltblue, .btn-ltblue:visited {
	color: #FFF;
	background-color: #58B2BA !important;
}
.btn-ltblue:hover, .btn-ltblue:focus {
	color: #FFF;
	background-color:#5881ba;
}
.btn-gray, .btn-gray:visited {
	background-color: #ced5e1;
	color: #0c2c61;
}
.btn-gray:hover {
	color: #FFF;
	background-color: #12408c;
}
 @media only screen and (min-width: 768px) {
}
 @media only screen and (min-width: 1200px) {
.btn-lg {
 font-size:30px;
 padding-right: 30px;
 padding-left: 30px;
 border-radius: 30px;
}
}
.btn-full {
	width:100%;
}
.btn-circle {
	border-radius:50%;
	width:30px;
	height:30px;
}
 @media only screen and (min-width: 768px) {
.btn-circle {
 width:20px;
 height:20px;
}
}
 @media only screen and (min-width: 992px) {
 .btn-circle {
 width:30px;
 height:30px;
}
}
/* backgrounds and colors */

.bg-blue {
	background-color: #0E316C;
}
.bg-teal {
	background-color: #58B2BA;
}
.bg-red {
	background-color: #e6675f;
}
.bg-white {
	background-color: #FFF;
}
.bg-ltgray {
	background-color: #f1f1f1;
}
.bg-ltgray2 {
	background-color:#F3F5F7;
}
.bg-graygradient {
	background: rgb(243,245,247);
	background: linear-gradient(90deg, rgba(243, 245, 247, 1) 0%, rgba(243, 245, 247, .85) 100%);
}
.bg-red, .bg-red h2, .bg-red h3, .bg-red p {
	color:#FFF;
}
.bg-red a {
	color:#FFF;
}
.dkgray {
	color: #444;
}
.gray {
	color: #666;
}
.black {
	color: #1f1f1f;
}
/* ================================================= */
/* ====================== header ================= */
/* =================================== ============== */

header {
	background-color:#FFF;
	padding-top:20px;
	padding-bottom:20px;
	position:relative;
}


/* ================================================= */

/* show and hide header */
#header {
	position: fixed;
	height: 100px;
	top: 0;
	width:100%;
	bottom: inherit;
	z-index: 8000;
	box-shadow:  0 0 6px rgba(0, 0, 0, 0.35);
	-moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
	-webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
	-o-box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
	border-bottom-width: 2px;
	border-bottom-style: solid;
	border-bottom-color: #b5bbc6;
	border-bottom-color: #ccc;
}
 @media only screen and (min-width: 992px) {
 #header {
/* height: 96px;*/
}
}
.headroom {
	will-change: transform;
	transition: transform 200ms linear;
	-moz-transition: transform 200ms linear;
	-webkit-transition: transform 200ms linear;
}
#header.headroom--not-top .header-border {
	display:none;
}
#header.headroom--not-top {
 transition: opacity .25s ease-in-out;
 -moz-transition: opacity .25s ease-in-out;
 -webkit-transition: opacity .25s ease-in-out;
	background-color:#f2f3f3;
	box-shadow:  0 0 6px rgba(0, 0, 0, 0.35);
	-moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
	-webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
	-o-box-shadow: 0 0 6px rgba(0, 0, 0, 0.35);
	height:80px;
	width:100%;
	left:0;
	right:0;
	border-bottom-width: 2px;
	border-bottom-style: solid;
	border-bottom-color: #b5bbc6;
}

header .logo img {
 width:160px;
 max-width:160px;

}


 @media only screen and (min-width: 768px) {

#header.headroom--not-top {
 height:90px;
 padding-top:20px;
}
}
 @media only screen and (min-width: 992px) {
#header.headroom--not-top .mobile-tools, #header.headroom--not-top .header-contact, #header.headroom--not-top .hideonscroll {
display:none;
}

header .logo img {
 width:184px;
 max-width:184px;

}
 #header.headroom--not-top .logo img {
 width:160px;
 padding:0;
}
 header nav.mainmenu {
 text-align:  center;
}
}
#header.headroom--pinned {
	-webkit-transform: translateY(0%);
	-moz-transform: translateY(0%);
	-o-transform: translateY(0%);
	-ms-transform: translateY(-100%);
	transform: translateY(0%);
}
#header.headroom--unpinned {
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transform: translateY(-100%);
}
#header.headroom--not-top nav li a, #header.headroom--not-top nav li a:visited {
}
#header.headroom--not-top nav ul {
	text-align:   center;
}
 @media only screen and (min-width: 992px) {
#header.headroom--not-top .justify-content-between {
 justify-content: center!important;
}
}
/* =================================================== */
/* ================= navigation menu ================= */
header nav.mainmenu {
	color: #363946;
	font-weight:600;
}
 @media only screen and (min-width: 768px) {
header nav.mainmenu {
 text-align: right;
}
}
header nav.mainmenu ul {
	margin: 0px;
	padding: 0px;
	list-style: none;
	line-height: 1;
	vertical-align: middle;
	letter-spacing: normal;
	text-align:   center;
}
header nav.mainmenu ul li {
	display: inline;
	text-align: center;
	padding:0;
	margin:0;
	vertical-align: middle;
	line-height:100%;
}
header nav.mainmenu li a, header nav.mainmenu li a:visited {
	display:inline-block;
	font-size: 16px;
	font-weight:  600;
	font-style:normal;
	color: #0E316C;
	text-transform: none;
	margin:0;
}


/* mobile nav displays till 992 */

 @media only screen and (min-width: 992px) {
 header nav.mainmenu li a {
 font-size: 16px;
 padding-left: .65rem;
 padding-right:.65rem;
}
}
 @media only screen and (min-width: 1200px) {
 header nav.mainmenu li a {
 font-size: 17px;
 padding-left: 1rem;
 padding-right:1rem;
}
}
 @media only screen and (min-width: 1600px) {
 header nav.mainmenu li a {
 font-size: 21px;

}
}
header nav.mainmenu li a:hover, header nav.mainmenu li a:focus {
	color: #58B2BA;
	text-decoration:none;
	border:none;
}
header nav.mainmenu li.current_page_item a, header nav.mainmenu li.active a {
	color: #0E316C;
	font-weight:700;
}
header nav.mainmenu li.current_page_item a:hover, header nav.mainmenu li.active a:hover {
	color: #58B2BA;
}
header nav.mainmenu li.current-page-ancestor > a {
	color: #0E316C;
}
.nav>li>a:focus, .nav>li>a:hover {
	background-color: transparent;
}
/* mobile navigation takeover overlay */


header .tools-wrapper {
	text-align:right;
}
header .tools-wrapper .item {
	display:inline;
	margin-left:10px;
}
header nav.mainmenu {
	color: #363946;
	font-weight:600;
}
 @media only screen and (min-width: 768px) {
header nav.mainmenu {
 text-align: right;
}
}
header nav.secondarymenu ul {
	margin: 0px;
	padding: 0px;
	list-style: none;
	line-height: 1;
	vertical-align: middle;
	letter-spacing: normal;
	text-align:   center;
}
header nav.secondarymenu ul li {
	display: inline;
	text-align: center;
	padding:0;
	padding-left: 1rem;
	margin:0;
	vertical-align: middle;
	line-height:100%;
}
header nav.secondarymenu li a, header nav.secondarymenu li a:visited {
	display:inline-block;
	font-size: 16px;
	font-weight:  600;
	font-style:normal;
	color: #0E316C;
	text-transform: none;
	margin:0;
}
header nav.secondarymenu li a:hover, header nav.secondarymenu li a:focus {
	color: #58B2BA;
}
header nav.secondarymenu li.button a, header nav.secondarymenu li.button a:visited {
	color: #FFF;
	background-color: #0E316C;
	font-weight: 600;
	letter-spacing:.03em;
	font-style: normal;
	text-transform:none;
	line-height: 1.42857143;
	text-align: center;
	white-space:  normal;
	vertical-align: middle;
	display: inline-block;
	text-decoration: none;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
	font-size: 16px;
	border-radius: 12px;
	padding: 6px;
	padding-right: 12px;
	padding-left: 12px;
}
header nav.secondarymenu li.button a:hover, header nav.secondarymenu li.button a:focus {
	color: #FFF;
	background-color: #12408c;
}






/* ==============    menu button ======================= */


.hamburger {
	display: inline-block;
	cursor: pointer;
	padding:0;
}
.hamburger:focus {
	outline:none;
	box-shadow:none;
}
.bar1, .bar2, .bar3 {
	display:block;
	width: 42px;
	height: 3px;
	background-color: #0E316C;
	margin: 8px 0;
}
/* close button */

.btn-close {
	position: absolute;
	top:25px;
	right:25px;
	z-index:9000;
	height:44px;
	width: 44px;
	line-height:1;
	background-color: transparent;
	padding:0;
	font-size:36px;
	color: #0E316C;
	border-radius: 50%;
	border: 2px solid #0E316C;
	-js-display: flex;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-bottom:3px;
}
 @media only screen and (min-width: 480px) {
.btn-close {
top:35px;
 right:35px;
}
}
/* =================================================== */
.menu-overlay {
	position: fixed;
	width: 100%;
	height: 100vh;
	z-index: 9999;
	top: 0;
	left: 0;
	bottom:0;
	right:0;
	background: #0E316C;
	background: -moz-linear-gradient(top, #0E316C 0%, #0E316C 100%);
	background: -webkit-linear-gradient(top, #0E316C 0%, #0E316C 100%);
	background: linear-gradient(to bottom, #0E316C 0%, #0E316C 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0E316C', endColorstr='#0E316C', GradientType=0 );
	overflow-y: hidden;
	transition: 0.5s;
	animation-duration: 2s;
	display:none;
}
.overlay-header {
	width:100%;
	height:96px;
	-js-display: flex;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.menu-overlay .btn-close {
	color: rgba(255, 255, 255, 0.90);
	border-radius: 50%;
	border: 2px solid rgba(255, 255, 255, 0.90);
}
.menu-overlay .btn-close:hover {
	color: #FFF;
	border: 2px solid #FFF;
}
.overlay-content {
	position: relative;
	width: 100%;
	/*height:100%;*/
	height: calc(100vh - 96px);
	text-align: center;
	-js-display: flex;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	align-items: flex-start;
	justify-content: center;
}
.menu-overlay nav {
	color: #FFF;
	padding-top:20px;
	padding-bottom:20px;
}
.menu-overlay nav ul {
	margin: 0px;
	padding: 0px;
	list-style: none;
	line-height: 1;
	vertical-align: middle;
	letter-spacing: normal;
	text-align: center;
}
.menu-overlay nav ul li {
	display: block;
	text-align: center;
	padding:0;
	margin:0;
	vertical-align: middle;
	line-height:100%;
}
.menu-overlay nav li a {
	display:block;
	font-size: 24px;
	line-height:135%;
	font-weight: 600;
	font-style: normal;
	margin:0;
	padding-top:12px;
	padding-bottom:12px;
}
.menu-overlay nav li ul li a {
	font-size: 20px;
	text-transform:  none;
}
.menu-overlay nav li a, .menu-overlay nav li a:visited {
	color: #FFF;
}
.menu-overlay nav li a:hover, .menu-overlay nav li a:focus {
	color: #11baec;
	border:none;
}
.menu-overlay nav li.current_page_item a {
	color: #11baec;
}
.menu-overlay nav li.current_page_item a:hover {
	color: #11baec;
}

/* mobile version */
.menu-overlay nav.secondarymenu li.button a, .menu-overlay  nav.secondarymenu li.button a:visited {
	color: #0E316C;
	background-color: #FFF;



	font-weight: 600;
	letter-spacing:.03em;
	font-style: normal;
	text-transform:none;
	line-height: 1.42857143;
	text-align: center;
	white-space:  normal;
	vertical-align: middle;
	display: inline-block;
	text-decoration: none;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
	font-size: 16px;
	border-radius: 12px;
	padding: 6px;
	padding-right: 12px;
	padding-left: 12px;




}

.menu-overlay  nav.secondarymenu li.button a:hover, .menu-overlay  nav.secondarymenu li.button a:focus {
	color: #12408c;
	background-color: #FFF;
}

/* ================================================= */
/* ====================== home page ================= */
/* ================================================= */


.home-spacing {
	padding-top:20px;
	padding-bottom:20px;
}
 @media only screen and (min-width: 768px) {
 .home-spacing {
 padding-top:10px;
 padding-bottom:10px;
}
}
.home-intro {
	padding-top:36px;
	padding-bottom:36px;
	background:#FFF;
}
.home-intro h1 {
	font-size:36px;
	font-size:8vw;
	line-height:110%;
	letter-spacing:-.04em;
	font-weight:600;
	color:#0E316C;
	margin:0;
}
.home-intro.gradient {
	/*background-image: linear-gradient(151deg, #4c39f3, #07e3a1);*/
	background-image: linear-gradient(151deg, #12408c, #58B2BA);
	background-image: linear-gradient(151deg, #10397c, #58B2BA);
}
.home-intro.gradient h1 {
	color:#FFF;

}
 @media only screen and (min-width: 768px) {
 .home-intro h1 {
 font-size:5vw;
}
}
 @media only screen and (min-width: 992px) {
 .home-intro h1 {
 font-size:5vw;
}
}
 @media only screen and (min-width: 1200px) {
 .home-intro h1 {
 font-size: 3.85vw;
}
}
 @media only screen and (min-width: 1500px) {
 .home-intro h1 {
 font-size: 60px;
}
}
.module-grid .module-item {
	padding-top:10px;
	padding-bottom:20px;
	margin-bottom:24px;
	background-color:#FFF;
	border-radius:4px;
	width:100%;
	max-width:220px;
	margin-left:auto;
	margin-right:auto;
	min-height: 160px;
	background: #ffffff;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: all 0.25s ease in;
	position: relative;
}
 @media only screen and (min-width: 768px) {
 .module-grid .module-item {
 width:100%;
 max-width:100%;
}
}
.module-grid .module-item img {
	width:auto;
	height:80px;
}
.module-grid .module-item p {
	font-weight: 600;
	font-size: 19px;
	text-align: center;
	color: #0e306c;
	margin:0;
	padding:0;
}
.infoIcon {
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;
	font-size: 1.2em;
}
 @media only screen and (min-width: 768px) {
 .module-grid .grid-box {
 padding:24px;
 padding-bottom:0;
}
}
/* home form */
.home-form {
	background-color:#F8F5F2;
	padding-top:50px;
	padding-bottom:50px;
}
.home-form blockquote {
	color: #1f1f1f;
}
.home-form h2 {
	color: #1f1f1f;
}
 @media only screen and (min-width: 1200px) {
 .home-form h2 {
 font-size:2.500vw;
}
.home-form p {
 font-size:28px;
}
.home-form .h3 {
 font-size:32px;
}
}

.form-control {
	display: block;
	width: 100%;
/* height: calc(1.5em + .75rem + 2px); */
 padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	/* border: 1px solid #ced4da; */
/* border-radius: .25rem; */
 transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.home-form .form-control {
 height: calc(2em + .75rem + 2px);
 padding: .5rem 1rem;
}
/* module grid new  */



@media only screen and (min-width: 768px) {
.home-help .item {
 text-align:right;
}
}
/* ================================================= */
/* ====================== interior ================= */
/* ================================================= */



main {
	min-height:400px;
}
 @media only screen and (min-width: 1200px) {
main {
 /* min-height:80vh; */
}
}
.content {
	padding-top:4px;
	padding-bottom:20px;
}
 @media only screen and (max-width: 767px) {
.content.mobile-wide {
 padding-top:20px;
 padding-bottom:20px;
}
}
 @media only screen and (min-width: 1200px) {
 .content {
 padding-top:10px;
 padding-bottom:10px;
}
}
/* Resources listing pages */

.article-list {
	background-color:#F3F5F7;
	padding-top:30px;
	padding-bottom:30px;
}
.article-list article {
	position:relative;
	background-color:#FFF;
	border-radius:4px;
	padding-top: 30px;
	padding-right: 50px;
	padding-bottom: 30px;
	padding-left: 50px;
	margin-bottom:0;
	box-shadow: 1px 4px 13px 7px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 1px 4px 13px 7px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 1px 4px 13px 7px rgba(0, 0, 0, 0.05);
	min-height: 90px;
	margin-bottom:40px;
	padding-bottom:40px;
}
@media only screen and (min-width: 768px) {
.article-list article {
 min-height: 200px;
}
}
 @media only screen and (min-width: 1200px) {
.article-list article {
 min-height: 180px;
}
}
 @media only screen and (min-width: 1400px) {
.article-list article {
 min-height: 160px;
}
}
.article-list a:hover {
	color:#0E316C;
}
.article-list :hover > h3 {
	color:#58B2BA;
}
.article-list article .btn-circle {
	position: absolute;
	bottom:20px;
	right:20px;
}
.article-list article:hover {
}
.article-list article p {
	margin:0;
	padding:0;
	line-height:136%;
	font-size:16px;
}
.video-list {
	padding-top:30px;
	padding-bottom:30px;
}
/* Resources detail pages */

.resource-detail {
}
.article-wrapper {
	background-color:#FFF;
	margin-bottom:20px;
}
 @media only screen and (min-width: 992px) {
 .resource-detail {
 background-color:#F3F5F7;
}
 .article-wrapper {
 background-color:#FFF;
 border-radius:4px;
 padding-top: 30px;
 padding-right: 50px;
 padding-bottom: 30px;
 padding-left: 50px;
 margin-bottom:0;
 box-shadow: 1px 4px 13px 7px rgba(0, 0, 0, 0.05);
 -webkit-box-shadow: 1px 4px 13px 7px rgba(0, 0, 0, 0.05);
 -moz-box-shadow: 1px 4px 13px 7px rgba(0, 0, 0, 0.05);
}
}
.article-wrapper p a {
	text-decoration:underline;
}
.article-wrapper blockquote {
	padding:30px;
	border-radius:9px;
	border: 1px solid #58B2BA;
	background-color:rgba(243, 245, 247, 0.3);
	margin-top:24px;
	margin-bottom:24px;
}
.article-wrapper blockquote p {
	font-weight:600;
	color:#0E316C;
}
/* sidebar subnav */


.content ul.subnav li a {
}
/* sidebar */

.content .sidebar ul.menu {
	margin:0;
	margin-bottom:30px;
}
.content .sidebar ul.menu li {
	font-size:18px;
}
.content .sidebar ul.menu li:before {
	content: '';
	display:none;
}
.content .sidebar ul.menu li.current-menu-item {
	font-weight:700;
}
/* contact form styles */
.wpcf7-form {
	padding-top:20px;
	padding-bottom:20px;
}
@media only screen and (min-width: 1200px) {
.wpcf7-form {
width:75%;
}
}
.modal .wpcf7-form {
	padding-top:0;
	padding-bottom:0;
}
.wpcf7-form-control-wrap.dynamictext-997 input {
	display:inline;
	width: auto;
	padding:0;
	-webkit-appearance: none;
	margin:0;
	font-size:  inherit;
	font-weight: inherit;
	color: inherit;
	border:none
}
span.wpcf7-not-valid-tip {
	color: #f00;
	font-size: 1em;
	font-weight: normal;
	display: block;
}
.content .wpcf7 p {
	text-align:left;
	margin-bottom:15px;
}
/* form.wpcf7-form label span {
	display: inline;
	width:100%;
	font-weight:400;
	font-size:16px;
}
span.wpcf7-form-control-wrap.your-consent > span.wpcf7-list-item-label {
	display:  inline;
}
form.wpcf7-form label {
	display: block;
	width:100%;
font-size:.9rem;
	font-weight:700;
	color:#58595B
}
form.wpcf7-form .wpcf7-form-control.g-recaptcha.wpcf7-recaptcha {
	margin-bottom:20px;
}
form input[type=text], form input[type=email], form input[type=tel], form textarea, form select {
	width:100%;
	padding:12px;
	-webkit-appearance:none;
	margin-top:6px;
	margin-bottom:6px;
	font-size:16px;
	font-weight:700;
	color:#3a3a3a;
	border-radius: 0;
	border: 2px solid #ccc;
}
form .input-group input[type=text] {
	height:60px;
	background: #FFF;
	font-size: 28px;
	border: none;
}
form .input-group input[type=text]:focus {
	border: none;
}
form ::-webkit-input-placeholder {
font-size:14px;
font-weight:400;
padding-left:4px;
color:#3a3a3a;
opacity:.85;
}
form :-moz-placeholder {
font-size:14px;
font-weight:400;
padding-left:4px;
color:#3a3a3a;
opacity:.85;
}
form ::-moz-placeholder {
font-size:14px;
font-weight:400;
padding-left:4px;
color:#3a3a3a;
opacity:.85;
}
form :-ms-input-placeholder {
font-size:14px;
font-weight:400;
padding-left:4px;
color:#3a3a3a;
opacity:.85;
}
.content span.wpcf7-list-item {
	display: block;
	margin: 0 0 0 1em;
}
form input[type=submit] {
	display: inline-block;
	margin-top: -1px;
	font-weight: 700;
	line-height: 1;
	text-transform:uppercase;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	background-color:#0E316C;
	color:#FFF;
	letter-spacing:.015em;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
	border: none;
	border-radius: 4px;
	padding-top: 12px;
	padding-right: 2rem;
	padding-bottom: 12px;
	padding-left: 2rem;
}
form input[type=submit]:hover {
	background-color:#12408c;
} */
/* -------- Search Results page --------- */

.search-results article {
	padding-bottom:21px;
	margin-bottom:21px;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: #CCCCCC;
}
.search-results h2 {
	font-size:22px;
}
/* ================================================= */
/* ====================== footer ================= */
/* ================================================= */

footer {
	width:100%;
	color:#FFF;
	background: #0e316c;
}
footer .item {
	margin-bottom:20px;
}
 @media only screen and (min-width: 768px) {
 footer .item {
 margin-bottom:0;
}
}
footer .logo {
	max-width:160px;
	margin-left:auto;
	margin-right:auto;
}
 @media only screen and (min-width: 768px) {
 footer .logo {
 margin-left:0;
}
}
.footer-top {
	padding-top:24px;
	padding-bottom:24px;
	text-align:center;
}
 @media only screen and (min-width: 768px) {
.footer-top {
 text-align:left;
}
}
.footer-btm {
	background-color:#284779;
	padding-top:18px;
	padding-bottom:18px;
	text-align:center;
	font-size:14px;
}
 @media only screen and (min-width: 768px) {
.footer-btm {
 text-align:left;
}
}
.footer-btm p {
	font-size:15px;
}
.footer-btm .account-tools {
	text-align:center;
/*	padding-top:40px;
	padding-bottom:50px;
	background: #0e316c;
	background: -moz-linear-gradient(left, #0e316c 0%, #12408c 100%);
	background: -webkit-linear-gradient(left, #0e316c 0%, #12408c 100%);
	background: linear-gradient(to right, #0e316c 0%, #12408c 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0e316c', endColorstr='#12408c', GradientType=1 ); */
}
 @media only screen and (min-width: 768px) {
 .footer-btm .account-tools {
 width:100%;
 text-align:right;
}
}
.footer-btm .btn-small {
	font-size: 14px;
	border-radius: 18px;
	padding: 4px;
	padding-right: 12px;
	padding-left: 12px;
	margin-left:6px;
	margin-right:6px;
}
/* footer menu */
nav.footermenu {
	font-size: 14px;
	font-weight:600;
	text-align:center;
}
nav.footermenu ul {
	margin:0;
	margin-top:20px;
	text-align:center;
	display:block;
	list-style:none;
}
nav.footermenu ul li {
	display:block;
	list-style:none;
	margin-bottom:6px;
}
 @media only screen and (min-width: 768px) {
nav.footermenu ul {
 margin:0;
 text-align:left;
}
nav.footermenu ul {
 display:inline-block;
}
 nav.footermenu ul li {
 display:inline-block;
 margin:0;
 margin-right:18px;
}
nav.footermenu li:after {
 content: '|';
 color: inherit;
 padding-left:18px;
}
 nav.footermenu ul li:last-child:after {
 content: '';
}
}
 @media only screen and (min-width: 992px) {
nav.footermenu ul {
 margin:0;
}
}
 @media only screen and (min-width: 768px) and (max-width: 991px) {
nav.footermenu ul li:last-child {
 margin-right:0;
}
}
nav.footermenu li a, nav.footermenu li a:visited {
	color:#FFF;
}
nav.footermenu li a:hover, nav.footermenu li a:focus {
	color:#FFF;
	text-decoration:underline;
}
/* social icons */
footer .social {
	margin-top:15px;
	-js-display: flex;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	align-items: center;
	justify-content: center;
}
 @media only screen and (min-width: 768px) {
footer .social {
 justify-content: flex-end;
}
}
footer .social a {
	margin-right:15px;
	margin-left:7px;
}
@media only screen and (min-width: 992px) {
footer .social a {
 margin-left:0;
}
}
@media only screen and (min-width: 1800px) {
footer .social a {
margin-right:20px;
}
}
.social a {
	font-size:2em;
	border-radius: 50%;
	color: #FFF;
	line-height: 1;
	text-align: center;
	border: none;
	position: relative;
}
@media only screen and (min-width: 400px) {
.social a {
font-size:3em;
}
}
@media only screen and (min-width: 768px) {
.social a {
font-size:1.25em;
}
}
@media only screen and (min-width: 992px) {
.social a {
font-size:1.5em;
}
}
@media only screen and (min-width: 1200px) {
.social a {
font-size:2em;
width: 39px;
height: 39px;
}
}
@media only screen and (min-width: 1800px) {
.social a {
font-size:2.5em;
width: 63px;
height: 63px;
}
}
.social a:link, .social a:visited {
	opacity:1;
}
.social a:hover, .social a:focus {
	color: #FFF;
	text-decoration:none;
	opacity:.6;
}
