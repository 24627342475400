.footer {
  background-image: url(https://www.tcbrealtor.com/wp-content/themes/tcb-bs5/images/footer-background.jpg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  padding: 40px;
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: #f5f5f5;
  font-family: "Nunito Sans",sans-serif;

  .container {
    width: 95%;
    max-width: 95%;

    @media only screen and (min-width: 768px) {
      width: 90%;
      max-width: 90%;
    }
  }

  a, a:visited {
    color: #091f44;
    text-decoration: none;
    transition: all 0.12s ease-in-out 0s;

    &:hover, &:focus {
      color: #b7202f;
      text-decoration: none;
    }
  }

  .social a {
    color: #a1a1a1;
    margin-right: 0.75rem;
    font-size: 32px;
  }

  h2 {
    font-weight:700;
    padding:0;
    margin: 0 0 5px;
    color: #091f44;

    span {
      font-size: 70%;
    }
  }

  .siteinfo {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align:center;
  }

  nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &-contact {
    text-align: center;
  }

  @media only screen and (max-width: 767px) {
    text-align: center;

    h2 {
      margin-top: 20px;
    }
  }
}

.footer nav ul li a, footer nav ul li a:visited {
  color: #444 !important;
}