.container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

form.christy {
  transform: scale(0.95) translateY(-1em);
}

#house-image {
  display: block;
  width: auto;
  margin: 0 auto;
}

#house-title {
  text-align: center;
  margin-bottom: 2em;
}

#processed-image {
  display: block;
  width: auto;
  margin: 0 auto;
  transform: scale(1.5);
}

#documentation-button {
}

.modal-body .form-wrapper {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.rbt-aux .rbt-close {
  background-color: white;
  border: none;
  transform: scale(1.5);
  padding-right: 4px !important;
}

.rbt-aux .rbt-close span {
  color: rgba(0, 0, 0, 0.45);
}

form.christy .fieldset {
  max-width: 250px !important;
  min-width: 250px !important;
}

.content .nav-link.active {
  color: #007bff !important;
}

.content .nav-link {
  color: #000000 !important;
}

.modal-content {
  margin-top: 120px !important;
}

select.form-select {
}

select#term.form-select {
}

.form-group#mortgage-type {
}

div.form-check.form-check-inline {
  margin-left: 10px;
}

.input-group-text {
}

input.form-control,
input#income.form-control,
input#debts.form-control {
}

/* Exemplar work */

.state-input .form-floating .rbt,
.area-input .form-floating .rbt {
  margin-bottom: 0.5em;
}

.state-input .form-floating .rbt input[type="text"],
.area-input .form-floating .rbt input[type="text"] {
  border-radius: 0.6em;
  border: 1px solid rgba(0, 0, 0, 0.45);
  padding-left: 0.7em !important;
  padding-top: 1.2em !important;
}

.state-input img.select-down,
.area-input img.select-down {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='rgba(0,0,0,0.45)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
  width: 38px;
  height: 20px;
  right: 0em;
  top: 1em;
  padding: 0 0.7em;
}

img.select-down.term-down,
img.select-down.loan-down {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='rgba(0,0,0,0.45)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
  width: 38px;
  height: 20px;
  right: 1.5em;
  top: 1em;
  padding: 0 0.7em;
}

.sp-input .form-floating > .form-control {
  width: 100%;
  border-radius: 0.6em;
  margin-bottom: 0.5em;
  border-color: rgba(0, 0, 0, 0.45);
}
.sp-input .form-floating > input {
  padding-left: 1.9em !important;
}

.sp-input .form-floating > i {
  position: absolute;
  font-style: normal;
  left: 0.85em;
  top: 1.38em;
}

.sp-input .form-floating > i.percent {
  position: absolute;
  font-style: normal;
  font-size: 15px;
  left: 0.85em;
  top: 1.5em;
}

a.tooltiplabel.alert-expand,
.sp-input .form-floating .tooltippanel,
.loan-input .form-floating .tooltippanel,
.term-input .form-floating .tooltippanel,
.radio-input .form-floating .tooltippanel {
  position: absolute;
  display: block;
  width: 25px;
  text-align: center;
  font-style: normal;
  cursor: pointer;
  right: 0.3em;
  top: 0.9em;
  z-index: 2;
}

.date-picker-input .form-floating .tooltippanel {
  position: absolute;
  display: block;
  width: 25px;
  text-align: center;
  font-style: normal;
  cursor: pointer;
  right: 10px;
  top: 20px;
  z-index: 2;
}

.loan-input-single .form-floating .tooltippanel {
  position: absolute;
  display: block;
  width: 25px;
  text-align: center;
  font-style: normal;
  cursor: pointer;
  right: 10px;
  top: 20px;
}

.rbt-token {
  margin-top: 1.2em;
}

.loan-input .form-floating .rbt {
  margin-bottom: 0.5em;
}

.loan-input .form-control {
  border-radius: 0.6em;
  border: 1px solid rgba(0, 0, 0, 0.45);
}

.radio-input {
  border-radius: 0.6em;
  border: 1px solid rgba(0, 0, 0, 0.45);
  height: calc(3.2em + 2px);
  margin-bottom: 0.5em;
}

.radio-input .form-floating {
  padding-top: 1.1em;
}

@media only screen and (min-width: 1440px) {
  .radio-input .form-floating {
    padding-left: 6em;
  }
}
@media only screen and (min-width: 1220px) and (max-width: 1439px) {
  .radio-input .form-floating {
    padding-left: 5em;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1219px) {
  .radio-input .form-floating {
    padding-left: 2em;
  }
}

.radio-input .form-floating .btn-group {
  transform: scale(0.85) translateY(0.2em);
}

.dropdown-menu {
  background-color: #fefefe;
  border: 1px solid rgba(0, 0, 0, 0.45);
}

.term-input .form-floating .rbt {
  margin-bottom: 0.55em;
}

.term-input .form-floating .rbt input[type="text"] {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.45);
  padding-left: 0.7em !important;
  padding-top: 0.95em !important;
}

.state-input .rbt-input-main {
  height: calc(3.2em + 2px) !important;
}

.area-input .rbt-input-main {
  height: calc(3.2em + 2px) !important;
}

.sp-input input {
  height: calc(3.2em + 2px) !important;
}

.term-input .rbt-input-main {
  height: calc(3.2em + 2px) !important;
}

.loan-input-single .rbt-input-main {
  height: calc(3.2em + 2px) !important;
}

.form-results .nav .nav-item button {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.form-results .nav {
  margin-bottom: 0 !important;
}

.form-results .nav .nav-item {
  margin-bottom: 0 !important;
}

.loan-input .rbt-input-main {
}

.loan-input .rbt-input-multi .form-control {
  padding-top: 28px;
}

.rbt-token .rbt-token-remove-button {
  border: none;
  background-color: inherit;
  transform: scale(0.9) translateY(0.1em);
}

#optional-inputs .accordion-body {
  padding: 10px 0 0 0;
}

/* #optional-inputs .ratio-input .form-floating {
	padding-top: 0.25em !important;
	padding-left: 0.25em !important;
} */

.date-picker-input .form-floating > .form-control {
  width: 100% !important;
  border-radius: 10px;
  margin-bottom: 0.55em;
  margin-left: 0 !important;
  border-color: rgba(0, 0, 0, 0.45);
}

.date-picker-input .react-date-picker__calendar {
  z-index: 3;
  background-color: white;
}

.date-picker-input .react-date-picker__wrapper {
  width: 80% !important;
  border: none;
}

.loan-input-single .form-floating .rbt {
  margin-bottom: 0.55em;
}

.loan-input-single .form-floating .rbt input[type="text"] {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.45);
  padding-left: 0.7em !important;
  padding-top: 0.95em !important;
}

.loan-input .rbt-input-multi .rbt-input-wrapper {
  padding-top: 1em;
  max-width: 80% !important;
}

.loan-input .rbt-input-multi.form-control {
  /* border: 2px dotted red; */
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.45);
  /* height: calc(3.2em + 2px) !important; */
  height: auto;
}

.loan-input .form-floating > label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.9em) translateX(0.15em);
}
.state-input .form-floating > label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.9em) translateX(0.15em);
}

.area-input .form-floating > label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.9em) translateX(0.15em);
}

.loan-input-single .form-floating > label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.9em) translateX(0.15em);
}

.radio-input .form-floating > label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.9em) translateX(0.15em);
}

.term-input .form-floating > label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.9em) translateX(0.15em);
}

.sp-input .form-floating > label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.9em) translateX(0.15em) !important;
}

.accordion-button {
  padding-top: 0 !important;
  padding-left: 0.7em !important;
}

.accordion-button::after {
  margin-top: 0.5em;
}

.btn-generate-pdf {
  padding: 0 !important;
}

.btn-download-pdf {
}

.dropdown-check {
  position: absolute;
  right: 0.5em;
  color: #1890ff;
}
