.headersmall {
  width: 100%;
  padding: 10px 25px;
  top: 0;
  bottom: inherit;
  z-index: 8000;
  background-color: #fff;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 20%);
  font-family: "Nunito Sans",sans-serif;
  font-size: 16px;
  font-weight: 600;

  & .dropdown .dropdown-menu {
    min-width: 12rem
  }

  .navbar-nav .nav-link {
    padding-top: 0;
    padding-bottom: 0;

    @media (max-width: 992px) {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  .container {
    width: 95%;
    max-width: 95%;
  }

  & .navbar-nav .dropdown-menu {
    position: absolute;
    padding: 5px;
  }

  .logo {
    max-width: 180px;
  }

  & .btn.btn-outline, & .btn.btn-outline:visited {
    font-size:14px;
    background-color: transparent;
    color: #545454;
    border: 1px solid #545454;
    border-radius: 3px;
    font-weight: 700;

    &:hover, &:focus {
      color: #FFF;
      background-color: #091f44;
      border: 1px solid #091f44;
    }
  }

  @media only screen and (min-width: 992px) {
    width: 90%;
    left:5%;
    right:5%;
    background-color:rgba(255, 255, 255, 0.95);
  }
}

.header {
  position: fixed;
  top: 0;
  bottom: inherit;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 8000;

  &-contact {
    margin-bottom: 14px;

    &-phone {
      font-weight: normal;
      font-size: 20px;
      margin-right: 10px;
    }
  }
}

.custom-navbar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 992px) {
    align-items: flex-start;
    padding: 20px 0;
  }
}

.btn.btn-outline:hover, .btn.btn-outline:focus {
  color: #f4f4f4;
  border: 2px solid #f4f4f4;
}

.navbar-light .navbar-nav .nav-link {
  color: #343434;
}
