.serv ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
}

.serv ul li {
  list-style: none;
  flex: 0 0 33.333333%;
  list-style-type: none;
}

.box {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
}

.flex-container {
  display: flex;
}

.flex-child {
  flex: 1;
  border: 2px solid yellow;
}

.flex-child:first-child {
  margin-right: 20px;
}
