.carousel .carousel-indicators button {
  background-color: #0E316C;
  border-top: 0;
  border-bottom: 0;
  border-radius: 3px;
}

.carousel .carousel-indicators .active {
  background-color: #0E316C;
}

.carousel-help-card {
  height: 100%;
  width: 360px;
  padding: 32px;
  border: 0 !important;
  border-radius: 10px !important;
  box-shadow: 1px 4px 13px 7px rgba(0, 0, 0, 0.05);

  @media (max-width: 992px) {
    padding: 28px;
    width: 300px;
  }

  &-wrapper {
    height: 100%;
    padding: 0 20px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & &__img {
    width: 55px;
    display: block;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 992px) {
      width: 47px;
    }

  }

  &__title.h5 {
    font-size: 19px;
    text-align: center;
    font-weight: 700;
    color: #0E306C;

    @media (max-width: 992px) {
      font-size: 17px;
    }
  }

  &__description {
    font-size: 16px;

    @media (max-width: 992px) {
      font-size: 14px;
    }
  }

  &__link {
    display: block;
    text-decoration-line: underline !important;
    font-weight: 700;
  }

  &__video {
    font-weight: 700;
    color: #57AFB9 !important;
  }

  & &__link + &__video {
    margin-left: 0;
  }
}

.help-carousel__item {
  height: 530px;

  @media (max-width: 992px) {
    height: 480px;
  }
}

.carousel .carousel-control-prev {
  left: -45px;
  width: 10%;

  @media (max-width: 992px) {
    left: 0;
  }
}

.carousel .carousel-control-next {
  right: -45px;
  width: 10%;

  @media (max-width: 992px) {
    right: 0;
  }
}

.help-section__title {
  @media (max-width: 992px) {
    text-align: center;
  }
}
